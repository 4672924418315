



































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HowToPlayPage extends Vue {
  // eslint-disable-next-line class-methods-use-this
  get games(): { text: string; route: string }[] {
    return [
      { text: 'บาคาร่า', route: 'Baccarat' },
      { text: 'บาคาร่า ประกันภัย', route: 'BaccaratInsurance' },
      { text: 'บาคาร่าไม่มีคอมมิชชั่น', route: 'BaccaratNoCommission' },
      { text: 'เสือมังกร', route: 'DragonTiger' },
      { text: 'ไฮโล ไฮเทค', route: 'Sicbo' },
      { text: 'ไฮโล พื้นบ้าน', route: 'HiloLocal' },
      { text: 'รูเล็ต', route: 'Roulette' },
      { text: 'ยี่กี', route: 'Yeekee' },
      { text: 'คีโน่ คลาสสิก', route: 'KenoClassic' },
    ];
  }
}
